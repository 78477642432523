<template>
  <div class="main-styles">
    <p>
      足球<br />一般规则<br />1、除非另有注明，所有足球投注的结算皆以球赛所规定的完场时间90分钟为准。
    </p>
    <p>
      2、完场时间90分钟包括球员伤停补时。加时赛、淘汰赛、点球，以及赛事后如果裁判或相关管理机构更改任何赛果则不计算在内。
    </p>
    <p>
      3、除非在个别玩法规则另有注明，所有滚球投注的结算将以90分钟的赛果为准。
    </p>
    <p>
      4、完场时间45分钟是指赛事上半场，其中包含伤停补时。加时赛、淘汰赛、点球，以及赛事后如果裁判或相关管理机构更改任何赛果则不计算在内。
    </p>
    <p>
      5、对于某些以全场完场时间为80分钟（2
      x40分钟）的特定赛事或者友谊赛，所有投注的结算皆以完场时间为准。
    </p>
    <p>
      6、若少年赛、友谊赛的完场时间为70分钟（2 x
      35分钟）或更少，本公司将在开赛前做出公布，否则该场赛事的注单一律作废。
    </p>
    <p>
      7、如果赛事中断或延迟，并且没有在12小时内重新开始，所有该场赛事的投注即被视为无效且取消，除非在个别投注类型规则里另有指定注明。
    </p>
    <p>
      8、如果赛事确认取消，所有该场赛事的投注即被视为无效且取消，除非在个别投注类型规则里另有指定注明。如果赛事是在上半场中断，所有上半场的注单将被取消。如果赛事是在下半场中断所有上半场的投注保持有效，但所有下半场的注单将被取消，除非在个别玩法规则另有注明。
    </p>
    <p>9、除非在个别玩法规则另有注明，乌龙球将予以计算在内。</p>
    <p>
      10、如果比赛或赛事取消，中断或延迟并且没有在官方指定开球时间的12小时内重新开始，所有该场赛事的投注即被视为无效
      且取消，除非在个别体育规则里另有指定注明。
      某些无条件终止的盘口将会相应地结算。单独的体育规则中对此类盘口的结算程序做了说明。公司取消该赛事所有注单的结果被视为最终决定，无需参考官方赛事裁
      判或相关部门的决定。连串投注将会继续按照注单剩余赛事的赛果结算，该取消赛事的赔率将会按照1计算。
    </p>
    <p>
      11、对于国际赛事，只要变更的场地仍在同一个国家内，所有注单将保持有效。
    </p>
    <p>
      12、对于国际赛事，只要变更的场地仍在联赛原定举办的国家内，所有注单将保持有效。
    </p>
    <p>
      13、本公司保留权利取消任何因更换场地而可能对赛事结果有影响的注单。<br />
      <br />
      主要市场<br />让球盘（亚洲让球盘）<br />一般规则<br />1、预测哪一支球队在盘口指定的让球数在全场/半场/赛事某个时节赢得比赛。
    </p>
    <p>
      2、“让球盘”则定义为在比赛正式开始前，一方球队已得到另一方让的虚拟分数，以领先的情况下开始比赛。
    </p>
    <p>3、所有注单将按盘口开出的让球数在玩法的时节结束后结算。</p>
    <p>
      4、让球队（优势球队）将给出让球，让球数将显示在赔率的左侧，让球队在盘面上也会以粗型字体显示
    </p>
    <p>
      5、让球盘的玩法分为以下几种：<br />整数让球也或称为让‘一球’（如：-1，-2，-3，等）<br />非整数让球也或称为‘半球’（如：-0.5，-1.5，-2.5，等）<br />混合以上让‘半球/一球’的方式（如：-0/0.5，-0.5/1，-1/1.5，等）
    </p>
    <p>
      <br />全场让球盘（亚洲让球盘）<br />1、根据盘口让球信息预测最终获得胜利的球队。
    </p>
    <p>2、投注的结算皆以球赛所规定的完场时间90分钟为准。</p>
    <p>3、如果赛事在90分钟结束前取消或中断，所有注单将会被视为无效。</p>
    <p>
      <br />半场让球盘（亚洲让球盘）<br />1、所有上半场的投注将以上半场45分钟其中包含伤停补时后的赛果结算。
    </p>
    <p>2、如果赛事在上半场时节因任何理由取消或中断，所有上半场注单将被取消。</p>
    <p>
      3、如果赛事在下半场或加时赛因任何理由取消或中断，所有上半场注单保持有效。
    </p>
    <p>
      <br />滚球让球盘<br />1、所有注单将按照盘口开出让球信息，在相应投注类型结束后结算。
    </p>
    <p>
      2、结算是以投注时到比赛/时节结束后的赛果做裁决。即是以赛事完场比分减去投注当时的比分。上半场的滚球让球投注将以上半场结束后的赛果结算。
    </p>
    <p>
      <br />全场补时（亚洲让球盘）<br />1、所有注单将按照盘口开出让球信息，在30分钟加时赛结束后计算，包含补时。
    </p>
    <p>2、如果赛事在加时赛结束前取消或中断，所有注单将会被视为无效。</p>
    <p>
      <br />半场补时（亚洲让球盘）<br />1、所有注单将按照盘口开出让球信息，在15分钟加时赛结束后计算，包含补时。
    </p>
    <p>2、加时赛中如果赛事在上半场取消或中断，所有上半场注单将会被视为无效。</p>
    <p>
      3、加时赛中如果赛事在下半场或补时阶段取消或中断，所有上半场注单将会被视为有效
    </p>
    <p>
      <br />15分钟比赛（亚洲让球盘）<br />1、所有注单将按照盘口开出让球信息，在15分钟加时赛结束后计算，包含补时。
    </p>
    <p>
      2、结算是以投注时到比赛/时节结束后的赛果做裁决。即是以赛事完场比分减去投注当时的比分。上半场的滚球让球投注将以上半场结束后的赛果结算。
    </p>
    <p>3、所有的投注将以开始下个时节前的赛果结算。</p>
    <p>
      4、如果赛事中断，所有当前15分钟时段的投注以及将要进行的下一个15分钟时段投注将视为无效，任何15分钟时段投注，如果该时段完整结束，注单将被视为有效。
    </p>
    <p>
      <br />大小盘<br />一般规则<br />1、预测赛事总入球数将大于或小于在盘口指定的大/小盘球数。
    </p>
    <p>
      2、如果赛事的总入球数多于盘口的大/小盘球数，则为大盘。如果少于盘口的大/小盘球数，则为小盘。
    </p>
    <p>3、所有注单将按盘口开出的大/小盘球数在玩法的时节结束后结算。</p>
    <p>
      4、大/小盘的玩法分为以下几种：<br />大/小于'一球'（如：2，3，4，等）<br />大/小于'半球'（如：1.5，2.5，3.5，等）<br />混合以上'半球/一球'的方式（如：1.5/2，2.5/3，3.5/4，等）
    </p>
    <p>
      5、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。请参考以上范例：<br />范例A：会员投注大于2.5球：<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;i. 赛事在比分2-1时中断。<br />&nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;ii.
      尽管赛事中断，但因结果已经明确并且若之后有任何潜在进球将对盘口结算裁决没有影响，所有此会员注单结算为赢。<br />范例B：会员投注小于2.5球：<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;i. 赛事在比分2-1时中断。<br />&nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;ii.
      尽管赛事中断，但因结果已经明确并且若之后有任何潜在进球将对盘口结算裁决没有影响，所有此会员注单结算为输。<br />范例C：会员投注大于3.5球：<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp;i. 赛事在比分2-1时中断。<br />&nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;ii.
      由于赛事在未有明确的结果能裁决会员的注单前中断，此会员的注单将被取消。
    </p>
    <p><br />全场大小球<br />1、所有的投注将以全场90分钟的赛果结算。</p>
    <p>
      2、如果比赛停止，取消或中断，所有投注将被视为无效，除非在赛事取消或中断前，结果已经明确。
    </p>
    <p><br />半场大小球<br />1、所有上半场的投注将以上半场45分钟的赛果结算。</p>
    <p>
      2、如果赛事在上半场时节因任何理由取消或中断，所有上半场注单将被取消。除非在赛事取消或中断前，结果已经明确。
    </p>
    <p>
      3、如果赛事在下半场或加时时段因任何理由取消或中断，所有上半场注单保持有效。
    </p>
    <p>
      <br />滚球大小盘<br />1、结算是以比赛/时节结束后的总入球数做裁决。投注当时，赛事的比分将视为0-0来计算。
    </p>
    <p>
      <br />加时赛-大小盘<br />1、两支球队开始加时赛的比分为0-0，之前赛果属于常规时间内入球不会计算在内。
    </p>
    <p>2、所有的投注将以30分钟加时赛后结果结算，包括补时。</p>
    <p>
      3、在加时赛结束前如果比赛停止，取消或中断，所有投注将被视为无效，除非在赛事取消或中断前，结果已经明确。
    </p>
    <p>
      <br />半场加时赛-大小盘<br />1、所有投注将会按照15分钟赛事结束后赛果为准，包含补时。
    </p>
    <p>2、如果比赛在上半场停止，取消或者中断，所有上半场注单将被视为无效。</p>
    <p>
      3、如果比赛在下半场或补时期间停止，取消或者中断，所有上半场注单将被视为有效。
    </p>
    <p>
      <br />单一球队大小盘<br />1、预测在特定的比赛有关期间内，其中一支球队的总入球数将大于或小于在盘口指定的大/小盘球数。
    </p>
    <p>
      2、如果赛事的总入球数多于盘口的大/小盘球数，则为大盘。如果少于盘口的大/小盘球数，则为小盘。
    </p>
    <p>
      3、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>4、预测赛事总入球数将大于或小于在盘口指定的大/小盘球数。</p>
    <p>
      5、如果总进球数多于盘口的大/小盘牌数，则为大盘。如果少于盘口的大/小盘牌数，则为小盘。
    </p>
    <p>
      6、在每个15分钟时开始，所有球队在开始此时节比赛都是0-0，之前的得分是没有影响的。
    </p>
    <p>
      7、如果比赛被中断，所有将要进行的15分钟时段投注将被视为无效。任何15分钟时段投注，如果该时段中赛事进行完整，此注单将被视为有效。在15分钟时段中，如果赛事已有明确结果并且之后对赛事没有任何影响，总进球数大/小盘注单才会被结算。任何其他的情况，投注将被视为无效。
    </p>
    <p>
      <br />1X2（独赢盘）<br />一般规则<br />1、预测哪一支球队将在比赛胜出。盘口提供两支球队和平局为投注选项。
    </p>
    <p>2、投注将以0-0的比分作为计算基础（让球并不计算在内）。</p>
    <p><br />全场独赢盘<br />1、预测哪一支球队将在90分钟比赛胜出或赛事和局。</p>
    <p><br />半场独赢盘<br />1、所有上半场的投注将以上半场45分钟赛果结算。</p>
    <p><br />滚球独赢盘<br />1、预测滚球时，哪一支球队胜出。</p>
    <p>2、投注的结算将以90分钟完场赛果为准。</p>
    <p>
      3、以下为滚球独赢盘范例。<br />范例A：在赛事比分为阿森纳1-0曼联时，会员投注滚球独赢盘
      – 阿森纳赢：<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; i.
      完场赛果为阿森纳2-1曼联。<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      ii. 因阿森纳在完场时胜出，所有投注阿森纳的注单结算为赢。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; iii.
      所有在比分1-0时投注曼联或平局的注单将结算为输。
    </p>
    <p>
      范例B：在赛事比分为阿森纳1-0曼联时，会员投注滚球独赢盘 –曼联赢：<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; i. 完场赛果为阿森纳1-1曼联。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ii.
      因完场赛果为平局，所有投注曼联以及阿森纳的注单将结算为输。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; iii. 所有投注平局的注单将结算为赢。
    </p>
    <p>
      4、加时赛则视为一场新的赛事并且会开出加时赛盘口。投注将按加时赛时节的结果做结算。<br />&nbsp;
      &nbsp; &nbsp;4.1、加时赛-独赢盘<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;
      &nbsp;4.1.1、预测哪一支球队将会在30分钟内胜出，或赛事平局，包含补时。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;4.1.2、如果比赛在加时赛结束前暂停，取消或中止，所有投注将被视为无效。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;4.2、半场加时赛-独赢盘<br />&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;
      &nbsp;4.2.1、预测哪一支球队将会在15分钟内胜出，或赛事平局，包含补时。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;4.2.2、如果赛事在加时赛上半场取消或中断，所有上半场注单将会被取消。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;4.2.3、如果赛事在加时赛下半场取消或中断，所有上半场注单将被会视为有效。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;4.3、15分钟-大小盘<br />&nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;
      &nbsp;4.3.1、预测在规定时段内哪一支球队将会获胜，赛事盘口将会提供两支球队，以及和局。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;4.3.2、所有两支球队在每时段开始时比分将按照0-0计算，之前比分不计算在内。<br />&nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;4.3.3、如果赛事中断，所有当前15分钟时段的投注以及将要进行的下一个15分钟时段投注将视为无效，任何15分钟时段投注，如果该时段完整结束，注单将被视为有效。
    </p>
    <p>
      <br />总进球：单/双<br />全场进球-单/双<br />1、预测90分钟内，赛事总入球对单或双。<br />2、若最终比分为：0-0，将会按照“双”来计算。
    </p>
    <p>
      <br />半场进球-单/双<br />1、预测45分钟赛事的总入球数是单数或双数。<br />2、若最终比分为：0-0，将会按照“双”来计算。
    </p>
    <p>
      <br />加时赛-单/双<br />1、预测加时30分钟赛事的总入球数是单数或双数，包含补时。<br />2、若最终比分为：0-0，将会按照“双”来计算
    </p>
    <p>
      <br />半场加时赛-单/双<br />1、预测加时赛15分钟内的总进球数是单数或双数，包括补时。<br />2、若最终比分为：0-0，将会按照“双”来计算。
    </p>
    <p>
      <br />15分钟-单 / 双<br />1、预测在规定时间内进球数是单或双。<br />2、所有两支球队在每时段开始时比分将按照0-0计算，之前比分不计算在内。<br />3、如果赛事中断，所有当前15分钟时段的投注以及将要进行的下一个15分钟时段投注将视为无效，任何15分钟时段投注，如果该时段完整结束，注单将被视为有效。
    </p>
    <p>
      <br />波胆<br />1、预测一场特定赛事中相关时间段的准确比分。<br />2、"任何其他比分"是指任何的比分，而不是一个市场选项列表类型。
    </p>
    <p>
      <br />波胆-全场<br />1、预测一场特定赛事的全场准确比分。<br />2、全场波胆投注的结算根据90分钟完场比分做出裁决。<br />3、如果赛事取消，全场波胆投注在“其它比分”为仅有可能获胜的选项，投注将被视为有效；所有其他的投注则被视为无效，此是由于赛事无条件决定后面的进球不会影响赛事的结果。
    </p>
    <p>
      <br />波胆-半场<br />1、预测一场特定赛事半场的准确比分。<br />2、半场波胆投注是指投注上半场的比赛，投注的结算根据半场“45分钟”结束后的比分做出裁决。<br />3、如果赛事在上半场取消，所有半场波胆投注在“其它比分”为仅有可能获胜的选项，投注将被视为有效；所有其他的投注则被视为无效，此是由于赛事无条件决定后面的进球不会影响赛事的结果。<br />4、如果赛事在下半场取消，所有半场波胆的投注被视为有效。
    </p>
    <p>
      <br />半场/全场<br />1、预测赛事的半场/全场领先球队。<br />2、如果赛事取消，
      所有注单将被退还。<br />选项:<br />&nbsp; &nbsp; &nbsp;
      &nbsp;主队领先上半场，主队全场胜出 (简称为 “主.主”)<br />&nbsp; &nbsp;
      &nbsp; &nbsp;主队领先上半场，全场打和 (简称为 “主.和”)<br />&nbsp; &nbsp;
      &nbsp; &nbsp;主队领先上半场，客队全场胜出 (简称为 “主.客”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;上半场打和，主队全场胜出 (简称为 “和.主”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;上半场打和，全场打和 (简称为 “和.和”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;上半场打和，客队全场胜出 (简称为 “和.客”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;客队领先上半场，主队全场胜出 (简称为 “客.主”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;客队领先上半场，全场打和 (简称为 “客.和”)<br />&nbsp;
      &nbsp; &nbsp; &nbsp;客队领先上半场，客队全场胜出 (简称为 “客.客”)
    </p>
    <p>
      <br />双赢盘<br />1、在三种可能出现的赛果中选择两种进行投注;
      主场赢或打平（1和X）, 客场赢或打平（2和X）或主场或客场赢（1和2）。
    </p>
    <p>
      2、共有三种选择: 1 X, X 2, 1 2：<br />&nbsp; &nbsp; &nbsp;"1" 代表:
      主场赢。<br />&nbsp; &nbsp; &nbsp;"X" 代表: 平手。<br />&nbsp; &nbsp;
      &nbsp;"2" 代表: 客场赢。
    </p>
    <p>3、如果比赛在中立场进行﹐列在盘面的上方球队则被视为主队。</p>
    <p></p>
    <p>
      进球集锦<br />总入球数<br />1、预测一个特定赛事相关时间段两队的总入球数。
    </p>
    <p><br />总入球数-全场<br />1、预测全场两队的总入球数。</p>
    <p>2、全场总入球数注单结算是根据全场“90分钟”为准。</p>
    <p>
      3、如果赛事中断，总入球数投注将仅结算当赛事进球7个或更多，这是由于任何后面的进球不会影响赛事结果。任何其他的情况，投注将被视为无效。
    </p>
    <p><br />总入球数-半场<br />1、预测半场两队的总入球数。</p>
    <p>2、半场总入球数注单仅限于上半场。注单结算是根据上半场“45分钟”为准。</p>
    <p>
      3、如果赛事中断，总入球数投注将仅结算当赛事进球4个或更多，这是由于任何后面的进球不会影响赛事结果。任何其他的情况，投注将被视为无效。
    </p>
    <p>
      <br />最先/最后进球球队<br />1、在法定比赛90分钟内，预测最先或最后进球的球队。
    </p>
    <p>
      2、乌龙球将予以计算为得分那方入球。比如: A队VS B队，B
      队踢进一个乌龙球造成比分1-0，此球计为A队先进球。
    </p>
    <p>3、如果赛事在有进球后中断，所有最先进球球队注单保持有效。</p>
    <p>4、如果赛事中断，所有最后进球球队注单将被取消。</p>
    <p>
      <br />滚球-下一只进球球队<br />1、预测在比赛进行时，哪一支球队会进下一个球。
    </p>
    <p>2、加时赛则视为一场新的赛事并且会开出加时赛盘口。</p>
    <p>3、请注意，乌龙球也属于进球范围内。</p>
    <p>
      4、如果赛事断，那么所有投注此注单将被视为有效，除非赛事已有明确结果并且之后对赛事没有任何影响。
    </p>
    <p></p>
    <p>
      角球<br />角球：一般规则<br />1、被裁定但并未实际执行的角球将不予以计算在内。
    </p>
    <p>2、注单的结算将根据官方赛果或赛事权威机构判定的结果为准。</p>
    <p>
      3、如果角球需重新进行(例如，在禁区内犯规)，重新进行的角球仍计为同一个角球。
    </p>
    <p>
      <br />角球：让球盘（亚洲盘口）<br />1、预测在90分钟完场时间内哪一支球队在盘口指定的让球数获得最多角球。
    </p>
    <p>2、所有注单将按盘口开出的让球数在玩法的时节结束后结算。</p>
    <p>
      <br />半场角球：让球盘（亚洲盘口）<br />1、预测在45分钟完场时间内哪一支球队在盘口指定的让球数获得最多角球。
    </p>
    <p>2、所有注单将按盘口开出的让球数在玩法的时节结束后结算。</p>
    <p>
      <br />总角球：大小盘<br />1、预测在90分钟后完成时间内（包括伤停补时）总获得的角球将大于或小于在盘口指定的大/小盘球数。
    </p>
    <p>
      2、如果赛事获得的总角球数多于盘口的大/小盘球数，则为大盘。如果少于盘口的大/小盘球数，则为小盘。
    </p>
    <p>
      3、如果赛事中断前已有明确结果并且之后没有任何显著会影响投注结果的情况，角球的大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      <br />半场角球：大小盘<br />1、预测在45分钟后完成时间内总获得的角球将大于或小于在盘口指定的大/小盘球数。
    </p>
    <p>
      2、如果赛事获得的总角球数多于盘口的大/小盘球数，则为大盘。如果少于盘口的大/小盘球数，则为小盘。
    </p>
    <p>
      3、如果赛事在上半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后角球对赛事没有任何影响。
    </p>
    <p>4、如果赛事在下半场中断，所有投注上半场角球大/小盘将会被视为有效。</p>
    <p>
      <br />总角球：独赢盘<br />1、预测哪一支球队将在90分钟比赛内获得更多角球数，盘口提供两支球队和平局为投注选项。
    </p>
    <p>
      2、如果赛事中断前已有明确结果并且之后角球对赛事没有任何影响，总角球数独赢盘注单将会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      <br />半场角球：独赢盘<br />1、预测哪一支球队将在45分钟比赛内获得更多角球数，盘口提供两支球队和平局为投注选项。
    </p>
    <p>
      2、如果赛事在上半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后角球对赛事没有任何影响。
    </p>
    <p>3、如果赛事在下半场中断，所有投注上半场角球独赢盘将会被视为有效。</p>
    <p>
      -<br />总角球：单双盘<br />1、预测90分钟内赛事的总角球数是单数或双数。
    </p>
    <p>2、若比赛没有角球，结果为0，投注‘双’注单将会盈利。</p>
    <p>
      3、如果赛事在上半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后角球对赛事没有任何影响。
    </p>
    <p>
      <br />半场角球：单双盘<br />1、预测45分钟内赛事的总角球数是单数或双数。
    </p>
    <p>
      2、如果赛事在上半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后角球对赛事没有任何影响。
    </p>
    <p>3、如果赛事在下半场中断，所有投注上半场角球单/双盘将会被视为有效。</p>
    <p>
      <br />第一个角球/最后一个角球<br />1、预测在90分钟完场时间内，第一个角球或最后获得角球的球队。
    </p>
    <p>2、如果赛事在获得第一个角球后中断，所有第一个角球的注单将保持有效。</p>
    <p>3、如果赛事中断，所有最后一个角球的注单将被取消。</p>
    <p>
      4、如果赛事并没有获得角球，所有第一个角球和最后一个角球的注单将被取消。
    </p>
    <p>
      <br />半场最多角球数<br />1、预测在90分钟完场时间内哪个半场将会获得最多角球。
    </p>
    <p>
      2、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，注单才会被结算。若遇到任何其他情况，注单将一律被取消
    </p>
    <p>
      <br />上半场角球：独赢盘<br />1、预测哪一支球队将在45分钟比赛内获得更多角球数，盘口提供两支球队和平局为投注选项。
    </p>
    <p>
      2、如果赛事在上半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后的角球对赛事没有任何影响。
    </p>
    <p>3、如果赛事在下半场中断，所有投注上半场角球独赢盘将会被视为有效。</p>
    <p>
      <br />下半场角球：独赢盘<br />1、预测哪一支球队将在第二段的45分钟比赛内获得更多角球数，盘口提供两支球队和平局为投注选项。
    </p>
    <p>
      2、如果赛事在下半场中断，所有注单将被视为无效，除非赛事已有明确结果并且之后的角球对赛事没有任何影响。
    </p>
    <p><br />首个角球时间<br />1、预测获得首个角球的时间。</p>
    <p>
      2、选项可列为：<br />&nbsp; &nbsp; &nbsp;a、赛事的第8分钟或之前。<br />&nbsp;
      &nbsp; &nbsp;b、第9分钟后。
    </p>
    <p>
      3、出于结算的用意，赛事的第一分钟是从1秒计算到59秒。第二分钟则是从1分钟计算到1分59秒，以此类推。
    </p>
    <p>
      4、范例：如果投注首个角球时间的选项是‘赛事的第8分钟或之前’，而确实踢角球的时间为8分钟49秒，踢角球时间的范围属于‘第9分钟后’，因此投注将结算为输。
    </p>
    <p>5、如果赛事在获得第一个角球后中断，所有首个角球时间的注单将保持有效。</p>
    <p>6、如果赛事在没有获得角球前中断，所有首个角球时间的注单将被取消。</p>
    <p>
      7、如果在90分钟完场时间内并未获得角球，所有首个角球时间的注单将被取消。
    </p>
    <p>8、如果首个角球需重新进行，那首个角球时间将以重新进行的角球时间为准。</p>
    <p></p>
    <p>点球<br />点球惩罚<br />1、预测在90分钟完场时间内是否会罚点球。</p>
    <p>
      <br />点球：让球盘<br />1、预测哪一支球队根据盘口指定的让球数在点球赛里获胜。
    </p>
    <p>2、骤死赛得分也包括在点球让球盘。</p>
    <p>3、如果赛事并未进行点球，所有注单将被取消。</p>
    <p>4、在90分钟完场时间内以及加时赛踢进的点球将不计算在内。</p>
    <p>
      <br />点球：大小盘<br />1、预测点球赛总入球数将大于或小于在盘口指定的大/小盘球数。
    </p>
    <p>
      2、点球大小盘只以10个点球为准（每队5球）。骤死赛得分不包括在点球大小盘。
    </p>
    <p>
      3、范例：<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; a、利物浦
      4-1托特纳姆热刺 – 大小球以5球结算。<br />&nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;
      b、利物浦6-5托特纳姆热刺（每队踢5个点球后的结果为：利物浦4-4托特纳姆热刺）-大小盘在每队踢5个点球后的8球得分结算。
    </p>
    <p>4、如果赛事并未进行点球，所有注单将被取消。</p>
    <p>5、在90分钟完场时间内以及加时赛踢进的点球将不计算在内。</p>
    <p>
      6、如果赛事在点球赛时中断，而在赛事中断前已有明确结果并且之后没有任何显著会影响投注结果的情况，大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p></p>
    <p>比赛<br />联赛：一般规则<br />1、赛果确认完成后将进行派彩。</p>
    <p>2、联赛的派彩将以官方来源或相关体育权威机构判定的结果为准。</p>
    <p>3、所有联赛的积分扣除都予以计算。</p>
    <p>4、冠军比赛规则适用。</p>
    <p><br />小组赛<br />1、预测整个赛季中排名最高的球队。</p>
    <p>
      <br />排名前（4，6，10）等<br />1、预测整个赛季中排名在前（4，6，10）等的球队。
    </p>
    <p><br />联赛：最后一名球队<br />1、预测整个赛季中排名在最后一名的球队</p>
    <p><br />联赛：被降级的球队<br />1、预测在比赛中哪一支球队会被降级。</p>
    <p>2、所有被降级球队将以全赢作为计算标准，比如：胜负不分的规则不适用。</p>
    <p>
      3、如果一支球队从联赛中被移除或清除，投注在此球队的注单将被视为无效。如果在赛季开始之前出现此情况，所有的投注都无效，将会另外开设盘口。
    </p>
    <p><br />联赛：球队保持原位<br />1、预测比赛中哪一支球队不会被降级。</p>
    <p>
      2、所有没有被降级的球队将以全赢作为计算标准，比如：胜负不分的规则不适用。
    </p>
    <p>
      3、如果一支球队从联赛中被移除或清除，投注在此球队的注单将被视为无效。如果在赛季开始之前出现此情况，所有的投注都无效，将会另外开设盘口。
    </p>
    <p><br />联赛：球队晋级<br />1、预测比赛中哪一支球队会晋级。</p>
    <p>2、投注包括自动晋级以及在特定比赛中通过加赛后的晋级。</p>
    <p>3、所有晋级的球队将以全赢作为计算标准，比如：胜负不分的规则不适用。</p>
    <p>
      4、如果一支球队从联赛中被移除或清除，投注在此球队的注单将被视为无效。如果在赛季开始之前出现此情况，所有的投注都无效，将会另外开设盘口。
    </p>
    <p>
      <br />联赛：最佳新秀<br />1、预测哪一支最新晋级的球队将在赛季中获得最高排名。
    </p>
    <p><br />比赛 - 进球最多的球队<br />1、预测在比赛中哪一个球队进球最多。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、进球数不包括点球。</p>
    <p>
      4、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p><br />比赛 - 失球最多的球队<br />1、预测在比赛中哪一个球队失球最多。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、在点球中的失球不予计算。</p>
    <p>
      4、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p><br />比赛 – 总进球数<br />1、预测在比赛中进球的数量。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、在比赛中点球的进球不予计算。</p>
    <p>
      4、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p>
      <br />比赛 - 帽子戏法<br />1、预测在比赛中任何一位球员进3个或以上的球。
    </p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、帽子戏法不包含点球中的进球。</p>
    <p>4、在一场比赛中如果一个球员进球3个或更多，即为帽子戏法。</p>
    <p>
      5、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的得分。如果帽子戏法是在赛事中断前，且赛事在0-0的情况下或者其它官方单位分配的比分下重新开始，将不予计算。
    </p>
    <p><br />比赛 - 总帽子戏法<br />1、预测在比赛中获得了多少帽子戏法。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、帽子戏法不包含点球中的进球。</p>
    <p>4、在一场比赛中如果一个球员进球3个或更多，即为帽子戏法。</p>
    <p>
      5、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。如果帽子戏法是在赛事中断前，且赛事在0-0的情况下或者其它官方单位分配的比分下重新开始，将不予计算。
    </p>
    <p><br />比赛 – 总红卡数<br />1、预测在比赛中红卡的数量。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、任何非球员的红卡（例如.经理、教练或替补）不予计算。</p>
    <p>4、点球中的红卡不予计算。</p>
    <p>5、如果赛事在出现红卡之后中断，红卡仍然计算在总红卡数中。</p>
    <p><br />比赛 – 总黄卡数<br />1、预测在比赛中黄卡的数量。</p>
    <p>2、所有的投注以赛事官方90分钟为完场时间，包括加时、伤停补时。</p>
    <p>3、任何非球员的黄卡（例如.经理、教练或替补）不予计算</p>
    <p>4、点球中的黄卡不予计算</p>
    <p>5、如果同个球员被出示第二张黄卡，第二张黄卡会被计算在内。</p>
    <p>
      <br />比赛 – 进球最多的城市<br />1、预测在比赛中哪一个城市将会进球最多。
    </p>
    <p>2、所有的投注以官方时间90分钟为准，包括加时、伤停补时。</p>
    <p>3、点球中的进球不予计算。</p>
    <p>
      4、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p><br />比赛 – 获胜小组<br />1、预测在比赛中哪一个小组将会获胜。</p>
    <p>2、冠军比赛规则适用。</p>
    <p>-<br />锦标赛 – 小组最后一名球队<br />1、预测哪一个球队为最后一名。</p>
    <p>2、冠军比赛规则适用。</p>
    <p><br />冠军所属地<br />1、预测比赛的冠军来自哪里。</p>
    <p>2、来源地可以是冠军球队的所属地区、国家或洲。</p>
    <p>3、冠军比赛规则使用。</p>
    <p><br />比赛 - 晋级<br />1、预测那支队伍会晋级去下一轮赛事。</p>
    <p>2、投注包括自动晋级以及在加时赛与点球大战后的晋级。</p>
    <p>3、符合冠军规则。</p>
    <p><br />比赛 - 阶段淘汰<br />1、预测比赛中该球队会在哪一个阶段被淘汰。</p>
    <p>2、冠军比赛规则使用。</p>
    <p><br />比赛 - 提名入围。<br />1、预测哪一支球队会进入决赛。</p>
    <p>2、冠军比赛规则适用。</p>
    <p><br />比赛 – 最终裁判员<br />1、预测决赛中的裁判员是哪一位。</p>
    <p>2、无论此前是否有任何公告，将根据决赛开始后的裁判为派彩依据。</p>
    <p>3、冠军比赛规则适用。</p>
    <p>
      <br />直接预测排名（联赛、比赛）<br />1、预测在比赛或联赛中哪两个球队获得第1名和第2名的顺序排名。
    </p>
    <p>2、所有的投注以官方时间90分钟为准，包括加时、伤停补时。</p>
    <p>
      3、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p><br />双预测排名<br />1、预测在比赛或联赛中哪两个球队为前两名的排名。</p>
    <p>2、所有的投注以官方时间90分钟为准，包括加时、伤停补时。</p>
    <p>
      3、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p><br />最佳射手<br />1、预测在一场特定比赛中进球最多的球员。</p>
    <p>2、如果产生超过一个冠军数量, 请以并列第一规则参考结算方式。</p>
    <p>
      3、投注在被列出的该球队球员将被视为有效，无论他们是否受伤、暂停、不参与比赛或其它任何原因。
    </p>
    <p>
      4、如果联赛中途有球员转到同一个联赛的另一个球队,
      球员在转到另一个球队前所进得球数将继续计算在内。如果球员是转到不同联赛的球队，在转之前进得球数将不会继续带到新联赛去。两种情况下，投注此球员的注单将保持有效。
    </p>
    <p>5、乌龙球将不予计算在内。</p>
    <p>
      6、按照单纯的联赛比赛玩法，只有在联赛中进得球才计算在内。在季后赛进得球将不予计算在内。
    </p>
    <p><br />最佳射手球队<br />1、预测比赛中哪一个球员在所属球队中进球最多。</p>
    <p>2、所有的投注以官方时间90分钟为准，包括加时、伤停补时。</p>
    <p>3、进球数不包括点球。</p>
    <p>4、投注适用于所有比赛的球队。</p>
    <p>
      5、胜负不分规则适用；任何用于决定和局的方法不可作为结算依据，比如：计数协助。
    </p>
    <p>
      <br />最佳射手/比赛双赢<br />1、预测比赛中哪一个球员进球最多和哪一支球队获胜。
    </p>
    <p>2、所有的投注以官方时间90分钟为准，包括加时、伤停补时。</p>
    <p>3、进球数不包括点球。</p>
    <p>
      4、如果多于一个球员和最佳射手打平，胜负不分规则使用；任何用于决定和局的方法不可作为结算依据，比如：计数协助。
    </p>
    <p><br />进球最多的小组<br />1、预测在比赛中哪一组进球最多。</p>
    <p>2、只计算在小组阶段的进球。</p>
    <p>3、所有的投注以赛事官方单位90分钟为完场时间，包括球员伤停补时。</p>
    <p>
      4、如果赛事中断，将以官方单位公布的最后赛果为准，其中包括赛事重新开始或指定的分数。
    </p>
    <p></p>
    <p>
      Other Markets<br />特定联赛里主客队的总进球数<br />1、本公司在某些联赛里会提供某种结合性赛事结果的投注。盘口的玩法将结合主场与客场球队在整个联赛里的赛果之后分出胜负。中立场的比赛，第一个球队被视为这一场赛事的主队。以下列出所提供的个别替补玩法规则。
    </p>
    <p>
      <br />特定联赛里主客队的总进球数：一般规则<br />1、如果联赛中有一场赛事中断或取消，所有特定联赛里主客队的总进球数注单将被取消。<br />2、比赛日程以及赛事场次将会明确的在盘口显示。例如：<br />&nbsp;
      &nbsp; &nbsp;a、主队-周五-3场赛事<br />&nbsp; &nbsp;
      &nbsp;b、客队-周五-3场赛事
    </p>
    <p>
      <br />特定联赛里主客队的进球数：让球盘<br />1、预测在90分钟完场时间内哪一支球队在结合整个联赛里的赛果后在盘口指定的让球数胜出。
    </p>
    <p>
      <br />特定联赛里主客队的进球数：大小盘<br />1、预测主客队的总进球数将大于或小于在盘口指定的大/小盘牌数。
    </p>
    <p><br />反波胆<br />1、预测赛事不会发生的最终比分</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />和球比分<br />1、预测赛事是否会以0-0或其他和局比分结束。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />双方皆得分<br />1、预测双方球队是否皆得分。</p>
    <p>
      2、如果赛事在双方球队皆得分后取消， 所有注单将被视为有效。结算为
      “双方皆得分”。
    </p>
    <p><br />零失球获胜<br />1、预测球队是否可以胜出并保持不失球。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />零失球<br />1、预测球队是否可以保持零失球。</p>
    <p>2、选择的球队只需保持不失球，无需在该赛事中胜出。</p>
    <p>3、如果赛事取消， 所有注单将被退还。</p>
    <p><br />赢得所有半场<br />1、预测哪一支队伍可以领先上/下半场。</p>
    <p>2、下半场成绩将以 0-0 重新开始。</p>
    <p>3、如果赛事取消， 所有注单将被退还。</p>
    <p><br />赢得任一半场<br />1、预测哪一支队伍可以领先其中一个半场。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />上/下半场皆得分<br />1、预测队伍是否可以在上/下半场皆得分。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      <br />三项让分投注<br />1、根据盘口开出信息预测最终获胜球队，包括“和局以”
      被视为第三个可能出现的结果。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      3、例子:<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;主队 (-1):
      主队须至少以领先两球或以上的比分胜出。<br />&nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp;和 (+1): 主队必须领先一球胜出。<br />&nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp;客队 (+1): 打和或客队胜出。
    </p>
    <p>
      <br />三项大小投注<br />1、根据盘口开出信息预测球赛总入球数，包括“和局”被视为第三个可能出现的结果。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      3、例子:<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 大 3:
      总进球数必须超过3球。<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 和 3:
      总进球数刚好3球。<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 小 3:
      总进球数不多余2球。
    </p>
    <p>
      <br />多重波胆<br />1、预测赛事的最终比分， 每一个投注选项包含三个比分，
      只要其中一个比分中奖即结算为赢。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>-<br />净胜球数<br />1、预测最终比分胜出一方的净胜球数。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />球队总进球数<br />1、预测其中一支球队的总进球数。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p><br />单一球队大/小<br />1、预测其中一支球队的最终得分为大或小。</p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      <br />全场独赢 &amp; 单双<br />1、预测赛事的结果
      （主，客，和）及比分为单或双。若其中一项投注输，注单即结算为 ‘输’。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      <br />全场独赢 &amp; 双方皆得分<br />1、预测赛事的结果
      （主，客，和）及赛事是否双方皆得分。若其中一项投注输，注单即结算未‘输’。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
    <p>
      <br />全场独赢 &amp; 大小<br />1、预测赛事的结果（主，客，和）及赛事最终比分为大或小。若其中一项投注输，注单即结算未‘输’。
    </p>
    <p>2、如果赛事取消， 所有注单将被退还。</p>
  </div>
</template>
<style lang="stylus" scoped></style>
